import {createSlice} from "@reduxjs/toolkit";

const mainSlice = createSlice({
    name: 'main',
    initialState: {
        activePanel: 'init',
        user: null,
        hash: '',
        postcard: {background: null, decor: null},
    },
    reducers: {
        setActivePanel(state, action) {
            state.activePanel = action.payload
        },
        setUser(state, action) {
            state.user = action.payload
        },
        setHash(state, action) {
            state.hash = action.payload
        },
        setPostcard(state, action) {
            state.postcard = action.payload
        },
    }
})

export default mainSlice.reducer
export const {setActivePanel, setUser, setHash, setPostcard} = mainSlice.actions
