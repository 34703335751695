import React from 'react';
import {useSelector} from "react-redux";
import ItemList from "../components/ItemList"

const MenuItemList = () => {

    const user = useSelector(state=>state.main.user)
    const count_items = Array.from(Object.keys(user).filter(it=>it.startsWith('decor') || it.startsWith('background')), x => user[x]).filter(el=>el==true).length

    let content = []
    for (let i=3; i<=7; i++) {
        if(i<=count_items) {
            let cl='elements-item_active'
            content.push(<ItemList num={String(i-2)} active={cl}/>)
        } else {
            let cl='elements-item_disable'
            content.push(<ItemList num={String(i-2)} active={cl}/>)
            }
    }

    return (
            <div className="elements-block__elements-items">
                {content}
            </div>
    );
};

export default MenuItemList;

