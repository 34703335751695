import React from 'react';
import {useSelector} from "react-redux";
import DecorItem from '../components/DecorItem'

const DecorList = () => {

    const user = useSelector(state=>state.main.user)
    let postcard = useSelector(state=>state.main.postcard)
    const decor_items = Array.from(Object.keys(user).filter(it=> it.startsWith('decor')), x => user[x])

    let content = []
    for (let i=1; i<=6; i++) {
            let cl
        if(decor_items[i-1]) {
            (postcard.decor === i) ? cl='elements-grid__item_active' : cl=''
            content.push(<DecorItem id={String(i)} num={String(i)} active={cl} btn_dis={false}/>)
        } else {
            cl='elements-grid__item_disable'
            content.push(<DecorItem id={String(i)} num={String(i)} active={cl} btn_dis={true}/>)
            }
    }
    return (
            <div className="elements-grid__items">
                {content}
            </div>
    );
};

export default DecorList;

