import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPostcard} from '../store/mainReducer'


const BackgroundItem = ({id, num, active, btn_dis}) => {

    const dispatch = useDispatch()
    const postcard = useSelector(state => state.main.postcard)

    function set_background(e) {
        dispatch(setPostcard({...postcard, background: Number(e.currentTarget.id.replace(/\D/g, ''))}))
    }

    return (
        <button
            id={`bc${id}`}
            disabled={btn_dis}
            onClick={set_background}
            className={`elements-grid__item ${active}`}>
            <img src={`images/background${num}.png`} loading="lazy" decoding="async" width="95" height="95" alt=""/>
        </button>
    );
};

export default BackgroundItem;

