import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"


const Modal = ({visible, setVisible, type}) => {

    const dispatch = useDispatch()
    
    let show_mod;
    let text = [];
    (visible) ? show_mod = 'overlay_active' : show_mod = '';
    if (type === "new_item") {
        text.push(<>Ты открыл<br/>новый элемент</>);
    } else if (type === "repeat") {
        text.push(<>Ты уже сканировал<br/>этот qr-code</>);
    } else if (type === 'wrong') {
        text.push(<>Этот qr-code<br/>неверный!</>);
    } else if (type === 'expired') {
        text.push(<>Время действия кода истекло<br/>истекло</>);
    } else if (type === 'shared') {
        text.push(<>Открытка опубликована</>);
    }

    function ok_btn () {
        setVisible(false)
        dispatch(setActivePanel('postcard'))
    }

    return (
    <div className={"overlay "+show_mod}>
        <div className="modal">
            <p className="modal__caption caption">{text}</p>
            <button 
                    onClick={ok_btn}
                    className="button modal__button">
                <span>OK</span>
            </button>
        </div>
    </div>
    );
};

export default Modal;

