import React,{useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer"
import MenuItemList from "../components/MenuItemList"

const Menu = () => {
    const dispatch = useDispatch()
    const user = useSelector(state=>state.main.user)
    let count_items = Array.from(Object.keys(user).filter(it=>it.startsWith('decor') || it.startsWith('background')), x => user[x]).filter(el=>el==true).length
    let content = []
    if (count_items >= 7) {
        content.push(<>Поздравляем!<br/>Ты участник розыгрыша призов!</>)
    } else {
        content.push(<>Получи 5 любых элементов<br/>и прими участие<br/>в розыгрыше золотых подвесок!</>)
    }



    return (
        <div className="container code-page">
            <p className="caption caption_size_normal code-page__caption">
                 летние мелодрамы на dомашнем
            </p>
            <p class="description description_size_small code-page__description">Смотри сериалы на телеканале Dомашний<br/><span>по будням в 19:00</span><br/><br/>Сканируй коды в эфире Dомашнего<br/>и получай элементы для своей открытки!</p>
            <button 
                onClick={()=>dispatch(setActivePanel('postcard'))}
                className="button code-page__button">
                <span>Собрать открытку</span>
            </button>
            <div class="assemble-card2">
              <img src="images/menuimg.png" loading="lazy" decoding="async" width="86" height="64" alt=''/>
            </div>
            <div className="elements-block code-page__elements-block">
                <p className="elements-block__caption">{content}</p>
                <MenuItemList/>
                <div className="elements-block__buttons">
                    <button 
                        onClick={()=>dispatch(setActivePanel('rules'))}
                        className="elements-block__button">
                        Как получить
                    </button>
                    <button 
                        onClick={()=>dispatch(setActivePanel('lottery'))}
                        className="elements-block__button">Правила  розыгрыша</button>
                </div>
            </div>
            <div className="container__footer">
                <a href="/" className="container__footer-logo">
                    <img src="images/footer-logo.png" loading="lazy" decoding="async" width="86" height="64" alt=""/>
                </a>
            </div>
        </div>
    );
}

export default Menu;
