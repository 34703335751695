import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Modal from "../components/Modal"
import { setUser } from "../store/mainReducer"
import ServerConnect from '../service';


const Choice = () => {
  const dispatch = useDispatch()
  const [choiceModal, setChoiceModal] = useState(false)
  const [typeModal, setTypeModal] = useState('new_item')
  const hash = useSelector(state => state.main.hash)
  const user = useSelector(state => state.main.user)
  let isBackBtnDisabled = (!(Array.from(Object.keys(user)
    .filter(el => el.startsWith('background')), x => user[x])
    .filter(el => el === true).length === 6)) ? [false, ''] : [true, 'button_disabled']
  let isDecorBtnDisabled = (!(Array.from(Object.keys(user)
    .filter(el => el.startsWith('decor')), x => user[x])
    .filter(el => el === true).length === 6)) ? [false, ''] : [true, 'button_disabled']

  const refs = {

    "Me8rj22": { num: 1, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "T8gi4kf": { num: 2, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "W23bsOg": { num: 3, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "S48Hsyo": { num: 4, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "Fe8rj22": { num: 5, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "S7W77ft": { num: 6, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "SLcJ83N": { num: 7, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "111JbDh7": { num: 8, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "20Pkfr8": { num: 9, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
    "376HgdB": { num: 10, st: new Date(2022, 6, 18, 19, 0, 0), end: new Date(2022, 6, 23, 12, 0, 0) },
  }

  useEffect(() => {
    if (Object.keys(refs).includes(hash)) {
      let cur_time = new Date()
      if (cur_time > refs[hash]['st'] && cur_time < refs[hash]['end']) {
        if (user[`ref${refs[hash]["num"]}`]) {
          setTypeModal('repeat')
          setChoiceModal(true)
        }
      } else {
        setTypeModal('expired')
        setChoiceModal(true)
      }
    } else {
      setTypeModal('wrong')
      setChoiceModal(true)
    }
  }, [])

  function random_choice(item_list) {
    let ind = Math.floor(Math.random() * item_list.length);
    return item_list[ind]
  }

  function get_choice(type) {
    if (type === 'background') {
      let back_items = Array.from(Object.keys(user).filter(el => el.startsWith('background')).filter(el => !user[el]))
      let new_back = random_choice(back_items)
      let ref_key = `ref${refs[hash]["num"]}`
      dispatch(setUser({ ...user, [`${new_back}`]: true, [`${ref_key}`]: true }))
      ServerConnect.sendPut('/api/user/' + user.vk_id, { [`${ref_key}`]: true, [`${new_back}`]: true }).then(data => setChoiceModal(true))
    } else if (type === 'decor') {
      let decor_items = Array.from(Object.keys(user).filter(el => el.startsWith('decor')).filter(el => !user[el]))
      let new_decor = random_choice(decor_items)
      let ref_key = `ref${refs[hash]["num"]}`
      dispatch(setUser({ ...user, [`${new_decor}`]: true, [`${ref_key}`]: true }))
      ServerConnect.sendPut('/api/user/' + user.vk_id, { [`${ref_key}`]: true, [`${new_decor}`]: true }).then(data => setChoiceModal(true))
    }
  }

  return (<>
    <div className="container question-page">
      <p className="caption caption_size_big question-page__caption">Что ты хочешь<br />
        получить?</p>
      <div className="question-block">
        <button
          disabled={isBackBtnDisabled[0]}
          onClick={() => get_choice('background')}
          className={"question-item " + isBackBtnDisabled[1]}>
          <span className="question-item__image">
            <img src="images/background.png" width="140" height="140" loading="lazy" decoding="async" alt="" />
          </span>
          <span className="question-item__caption">Фон</span>
        </button>
        <button
          disabled={isDecorBtnDisabled[1]}
          onClick={() => get_choice('decor')}
          className={"question-item " + isDecorBtnDisabled[1]}>
          <div className="question-item__image">
            <img src="images/decor.png" width="140" height="140" loading="lazy" decoding="async" alt="" />
          </div>
          <span className="question-item__caption">декор</span>
        </button>
      </div>
      <div className="container__footer">
        <a href="/" className="container__footer-logo">
          <img src="images/footer-logo.png" loading="lazy" decoding="async" width="86" height="64" alt="" />
        </a>
      </div>
    </div>
    <Modal visible={choiceModal} setVisible={setChoiceModal} type={typeModal} />
  </>
  );
};

export default Choice;

