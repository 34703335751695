import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {setActivePanel, setHash} from "../store/mainReducer"

const Init = () => {

    const dispatch = useDispatch()
    const [progress, setProgress] = useState('progress-10');
    const user = useSelector(state=>state.main.user)
    const hash = useSelector(state=>state.main.hash)


    useEffect(()=>{
        setProgress('progress-100')
        let rParams = window.FAPI.Util.getRequestParameters();
        if (rParams.custom_args) {
            const args = rParams.custom_args
            let ref = new URLSearchParams(args).get('ref')
            if (ref) {dispatch(setHash(ref))}
        }
        setTimeout(()=> {
            if (user.new_user) {
                dispatch(setActivePanel('info1'))
            } else if (hash !== '') {
                dispatch(setActivePanel('choice'))
            } else {
                dispatch(setActivePanel('menu'))
            }
        },3000)
    },[progress, user, hash])

    
    return (
     <div className="container start-screen">
          <a href="#" className="start-screen__logo">
              <img src="images/logo.png" width="194" loading="lazy" decoding="async" height="171" alt=""/>
          </a>
          <div className="progress-bar intro-block__progress-bar">
              <div className="progress-bar__line-wrapper">
                  <div className="progress-bar__line" style={{width: "50%"}}></div>
              </div>
          </div>
          <div className="loader start-screen__loader">
              <div className={"loader__bar "+progress}></div>
          </div>
      </div>
    );
};

export default Init;

