import React from 'react';
import BackBtn from "../components/BackBtn"

const Lottery = () => {
    return(
        <div class="container text-page">
            <BackBtn panel="menu"/>
            <p class="caption caption_size_big text-page__caption">Правила розыгрышей</p>
            <p class="description description_size_big text-page__description">Ты можешь участвовать сразу в трёх розыгрышах золотых подвесок!</p>
            <p class="text">1. Собери открытку и сделай репост к себе на стену.<br/>Ежедневно по будням мы будем выбирать 2-х победителей!</p>
            <p class="text">2. Отсканируй в эфире как минимум 5 разных QR-кодов.<br/>Ты автоматически станешь участником второго розыгрыша - 27 июля мы выберем 50 победителей!</p>
            <p class="text">3. Отсканируй все 10 QR-кодов.<br/>Ты станешь участником розыгрыша 1 из 10 золотых подвесок с изумрудом!</p>
            <div class="container__footer">
                <a href="/" class="container__footer-logo">
                    <img src="images/footer-logo.png" loading="lazy" decoding="async" width="86" height="64" alt=""/>
                </a>
            </div>
        </div>
    );
}

export default Lottery;

