import React, {useState} from 'react';
import {useSelector} from "react-redux";
import BackBtn from "../components/BackBtn"
import Modal from "../components/Modal"
import ServerConnect from '../service';

const PostCardDone = () => {

    const [choiceModal, setChoiceModal] = useState(false)
    const [typeModal, setTypeModal] = useState('new_item')
    const postcard = useSelector(state=>state.main.postcard)
    const user = useSelector(state=>state.main.user)
    let sign = Math.floor(((postcard.background+2)*postcard.background + (postcard.decor+3)*(postcard.decor*2)) / 2)
    let rParams = window.FAPI.Util.getRequestParameters();

    let a_href = ''
        if (rParams['mob_platform']==='android' || rParams['mob_platform']==='ios') {
            a_href = `https://ok.ru/apphook/outlink/${ServerConnect.front_url}/media/img/bpc${postcard.background}${postcard.decor}${sign}.jpg`
        } else {
            a_href = `${ServerConnect.front_url}/media/img/bpc${postcard.background}${postcard.decor}${sign}.jpg`
        }

    function api_callback(method, result, data) {
        if (method==="postMediatopic") {
            if (result==='ok') {
                ServerConnect.sendGet(`/api/counter/${user.vk_id}`);
                setTypeModal('shared')
                setChoiceModal(true)
            }
        }
    };
    window.API_callback = api_callback

    function share() {
        window.FAPI.UI.postMediatopic({
        "media":[
            {
              "type": "text",
              "text": `Я смотрю мелодрамы по будням в 19:00 на телеканале Dомашний и собираю открытки! 💌 
Присоединяйся и участвуй в розыгрыше 80 золотых подвесок! 

Кликай на картинку и переходи в приложение!
                                                          
https://ok.ru/app/domashniy_miniapp`
            },
          {
            "type": "app",
            "images": [
              {
                "url": `https://domestic.gamesmm.ru/media/img/bpc${postcard.background}${postcard.decor}${sign}.jpg`,
                "mark": "prize_1234",
                "title":""
              }
            ],
            },
          ]
        }, false);
    }

    return (
        <>
        <div className="container done-page">
            <BackBtn panel="postcard"/>
            <p className="caption caption_size_normal done-page__caption">Открытка готова!</p>
            <div className="assemble-card done-page__assemble-card">
                <div className="assemble-card__image-block">
                    <p className="assemble-card__caption">Твоя открытка</p>
                    <div className="assemble-card__image"> 
                        <img src={`images/postcard${postcard.background}${postcard.decor}.jpg`} alt=""/>
                    </div>
                </div>
            </div>
            <button
                onClick={share}
                className="button done-page__button">
                <span>Поделиться</span>
            </button>
            <a href={a_href} target="_blank" className="done-page__white-button">СКАЧАТЬ</a>
            <div className="container__footer">
                <a href="/" className="container__footer-logo">
                    <img src="images/footer-logo.png" loading="lazy" decoding="async" width="86" height="64" alt=""/>
                </a>
            </div>
        </div>
    <Modal visible={choiceModal} setVisible={setChoiceModal} type={typeModal} />
    </>
    );
}

export default PostCardDone;
