import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {setPostcard} from '../store/mainReducer'


const DecorItem = ({id, num, active, btn_dis}) => {

    const dispatch = useDispatch()
    const postcard = useSelector(state => state.main.postcard)

    function set_decor(e) {
        dispatch(setPostcard({...postcard, decor: Number(e.currentTarget.id.replace(/\D/g, ''))}))
    }

    return (
        <button
            id={`dec${id}`}
            onClick={set_decor}
            disabled={btn_dis}
            className={`elements-grid__item ${active}`}>
            <img src={`images/decor${num}.png`} loading="lazy" decoding="async" width="95" height="95" alt=""/>
        </button>
    );
};

export default DecorItem;

