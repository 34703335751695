import React from 'react';


const ItemList = ({num, active}) => {

    return (
        <div className={`elements-item ${active}`}>
            <p className="elements-item__value">{num}</p>
        </div>
    );
};

export default ItemList;

