import React from 'react';
import BackBtn from "../components/BackBtn"

const Rules = () => {

    return (
        <div class="container text-page">
            <BackBtn panel="menu"/>
            <p class="caption caption_size_big text-page__caption">Как получить новые элементы</p>
            <p class="description description_size_big text-page__description">Смотри наши летние сериалы по будням с 19:00 до 23:00</p>
            <p class="text">Во время показа сериалов будут появляться плашки с QR-кодами, которые нужно отсканировать камерой своего смартфона.</p>
            <p class="text">Ежедневно мы будем показывать два уникальных QR-кода.<br/>Каждый отсканированный QR-код - это новый элемент для твоей открытки!</p>
            <div class="container__footer">
                <a href="/" class="container__footer-logo">
                    <img src="images/footer-logo.png" loading="lazy" decoding="async" width="86" height="64" alt=""/>
                </a>
            </div>
        </div>
    );
}

export default Rules;
