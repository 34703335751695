import React, {useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer"
import BackBtn from "../components/BackBtn"
import DecorList from "../components/DecorList"
import BackgroundList from "../components/BackgroundList"

const PostCard = () => {
    const dispatch = useDispatch()
    let postcard = useSelector(state=>state.main.postcard)
    let isDisabled = (postcard.background && postcard.decor) ? [false, ''] : [true, 'button_disabled']


    return (
        <div className="container elements-page">
            <BackBtn panel="menu"/>
            <p className="description description_size_normal elements-page__description elements-page__description_order_first">Тут  собраны<br/>
                <span>все элементы открыток</span>,<br/>
                которые у тебя есть, и которые <br/>можно получить</p> 
            <p className="description description_size_normal elements-page__description">Шаг 1:</p>
            <div className="elements-grid elements-page__elements-grid">
                <p className="elements-grid__caption">
                    выбери фон
                </p>
                    <BackgroundList/>
            </div>
            <p className="description description_size_normal elements-page__description">Шаг 2:</p>
            <div className="elements-grid">
                <p className="elements-grid__caption">выбери декор</p>
                <DecorList/>
            </div>
            <div className="elements-page__buttons">
                <button
                    disabled={isDisabled[0]}
                    onClick={()=>dispatch(setActivePanel('postcarddone'))}
                    className={"button "+ isDisabled[1]}>
                    <span>Собрать</span>
                </button>
                <button 
                    onClick={()=>dispatch(setActivePanel('rules'))}
                    className="elements-page__white-button">
                    Как получить больше?
                </button>
            </div>
            <div className="container__footer">
                <a href="/" className="container__footer-logo">
                    <img src="images/footer-logo.png" loading="lazy" decoding="async" width="86" height="64" alt=""/>
                </a>
            </div>
        </div>
    );
}

export default PostCard;
