import React, {useEffect} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {setUser} from "./store/mainReducer";
import ServerConnect from "./service";
import Init from "./panels/Init";
import Menu from "./panels/Menu";
import Info1 from "./panels/Info1";
import Info2 from "./panels/Info2";
import Info3 from "./panels/Info3";
import PostCard from "./panels/PostCard";
import PostCardDone from "./panels/PostCardDone";
import Rules from "./panels/Rules";
import Lottery from "./panels/Lottery";
import Choice from "./panels/Choice";


const App = () => {

  const dispatch = useDispatch()
  const activePanel = useSelector(state => state.main.activePanel)

  useEffect(() => {
    function fetchData() {
        let rParams = window.FAPI.Util.getRequestParameters();
        window.FAPI.init(rParams["api_server"], rParams["apiconnection"],
            function() {
                let user_id = window.FAPI.Util.getRequestParameters()["logged_user_id"];
                get_user_info(user_id, rParams)
            },
            function(error) {
                console.log(error)
            }
        );
     }
    fetchData()
  }, [])

  async function get_user_info (user_id, rParams) {

    let response = await fetch(ServerConnect.api_url + '/api/user/' + user_id, );
    if (response.status === 200) {
      let user_info = await response.json()
        user_info.user['new_user'] = user_info.new_user
        dispatch(setUser(user_info.user))
      if (user_info.user['new_user']) {ServerConnect.sendPut('/api/user/'+user_id, {"user_name":rParams['user_name']})}
    }
  }

  let content = []
  if (activePanel === 'init') {
    content.push(<Init key='init'/>)
  } else if (activePanel === 'menu') {
    content.push(<Menu key='menu'/>)
  } else if (activePanel === 'info1') {
    content.push(<Info1 key='info1'/>)
  } else if (activePanel === 'info2') {
    content.push(<Info2 key='info2'/>)
  } else if (activePanel === 'info3') {
    content.push(<Info3 key='info3'/>)
  } else if (activePanel === 'postcard') {
    content.push(<PostCard key='ps'/>)
  } else if (activePanel === 'postcarddone') {
    content.push(<PostCardDone key='psdone'/>)
  } else if (activePanel === 'rules') {
    content.push(<Rules key='rules'/>)
  } else if (activePanel === 'lottery') {
    content.push(<Lottery key='lottery'/>)
  } else if (activePanel === 'choice') {
    content.push(<Choice key='choice'/>)
  }
  return (
      <div className='app'>
        {content}
      </div>
  );
}
export default App;
