import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"

const Info1 = () => {

    const dispatch = useDispatch()

    return (
        <div className="container slider-page">
            <div className="slider">
                <div className="slider__rail">
                    <div className="slide-item">
                        <div className="slide-item__image">
                            <img width="387" height="206" src="images/slider-pic1.png" alt=""/>
                        </div>
                        <p className="slide-item__caption caption caption_size_normal">Проведи лето<br/>
                            вместе с dомашним!</p>
                        <p className="slide-item__description description description_size_big">Смотри наши сериалы<br/>
                            по будням в 19:00</p>
                        <button 
                            onClick={()=>dispatch(setActivePanel('info2'))}
                            className="button slide-item__button slide-item__button_swipe_next">
                            <span>Далее</span>
                            <img width="18" height="12" src="images/arrow-icon.svg" alt=""/>
                        </button>
                    </div>
                </div>
                <div className="slider__nav">
                    <button className="slider__nav-button slider__nav-button_active"></button>
                    <button className="slider__nav-button"></button>
                    <button className="slider__nav-button"></button>
                </div>
            </div>
        </div>
    );
};

export default Info1;

