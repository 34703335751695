import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"



const BackBtn = ({panel}) => {
    const dispatch = useDispatch()
    
    return (
        <button
            onClick={()=>dispatch(setActivePanel(panel))} 
            className="container__back-button">
            <img src="images/arrow-back-icon.svg" alt="back"/>
        </button>
    );

};

export default BackBtn;

