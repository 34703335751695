import React from 'react';
import {useSelector} from "react-redux";
import BackgroundItem from '../components/BackgroundItem'


const BackgroundList = () => {

    const user = useSelector(state=>state.main.user)
    let postcard = useSelector(state=>state.main.postcard)
    const background_items = Array.from(Object.keys(user).filter(it=> it.startsWith('background')), x => user[x])

    let content = []
    for (let i=1; i<=6; i++) {
        if(background_items[i-1]) {
            let cl;
            (postcard.background === i) ? cl='elements-grid__item_active' : cl=''
            content.push(<BackgroundItem id={i} num={String(i)} active={cl} btn_dis={false}/>)
        } else {
            let cl='elements-grid__item_disable'
            content.push(<BackgroundItem id={i} num={String(i)} active={cl} btn_dis={true}/>)
            }
    }
    return (
            <div className="elements-grid__items">
                {content}
            </div>
    );
};

export default BackgroundList;

