import React from 'react';
import {useDispatch} from "react-redux";
import {setActivePanel} from "../store/mainReducer"

const Info2 = () => {
    const dispatch = useDispatch()

    return (
        <div className="container slider-page">
            <div className="slider">
                <div className="slider__rail swiper-wrapper">
                    <div className="slide-item swiper-slide">
                        <div className="slide-item__image">
                            <img width="305" height="188" src="images/slider-pic2.png" alt=""/>
                        </div>
                        <p className="slide-item__caption caption caption_size_normal">Создай уникальные<br/>
                            открытки для друзей</p>
                        <p className="slide-item__description description description_size_big">Сканируй QR-коды в эфире<br/>
                            и собирай свои открытки</p>
                        <button 
                            onClick={()=>dispatch(setActivePanel('info3'))}
                            className="button slide-item__button slide-item__button_swipe_next">
                            <span>Далее</span>
                            <img width="18" height="12" src="images/arrow-icon.svg" alt=""/>
                        </button>
                    </div>
                </div>
                <div className="slider__nav">
                    <button className="slider__nav-button"></button>
                    <button className="slider__nav-button slider__nav-button_active"></button>
                    <button className="slider__nav-button"></button>
                </div>
            </div>
        </div>
    );
}

export default Info2;

