import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {setActivePanel} from "../store/mainReducer"

const Info3 = () => {
    const dispatch = useDispatch()
    const hash = useSelector(state=>state.main.hash)

    function forward() {
        console.log(hash)
        if (hash !== '') {
            dispatch(setActivePanel('choice'))
        } else {
            dispatch(setActivePanel('menu'))
        }
    }

    return (
        <div className="container slider-page">
            <div className="slider">
                <div className="slider__rail swiper-wrapper">
                    <div className="slide-item swiper-slide">
                        <div className="slide-item__image">
                            <img width="225" height="255" src="images/slider-pic3.png" alt=""/>
                        </div>
                        <p className="slide-item__caption caption caption_size_normal">участвуй в розыгрыше<br/>
                            летних призов</p>
                        <p className="slide-item__description description description_size_big">Выиграй одну из 80 золотых подвесок!</p>
                        <button 
                            onClick={forward}
                            className="button slide-item__button">
                            <span>ПОЕХАЛИ!</span>
                        </button>
                    </div>
                </div>
                <div className="slider__nav">
                    <button className="slider__nav-button"></button>
                    <button className="slider__nav-button"></button>
                    <button className="slider__nav-button slider__nav-button_active"></button>
                </div>
            </div>
        </div>
    );
}

export default Info3;
